<template>
<v-form lazy-validation>
  <v-container>
    <v-col md="4" offset-md="4">
      <h1 style="margin: -12px -12px 0.5rem;">Register</h1>
      <v-row>
        <v-text-field label="Username" v-model="username" autocomplete="username" required>
        </v-text-field>
      </v-row>
      <v-row>
        <v-text-field label="Email" v-model="email" autocomplete="email" required>
        </v-text-field>
      </v-row>
      <v-row>
        <v-text-field label="Password" v-model="password" autocomplete="current-password" required>
        </v-text-field>
      </v-row>
      <v-row class="justify-center">
        <v-btn color="success" v-on:click="register(username, email, password)">
          Register
        </v-btn>
      </v-row>
      <v-row v-if="message !== ''" class="justify-center">
       <p> {{message}}</p>
      </v-row>
    </v-col>
  </v-container>
</v-form>
</template>

<script>
import axios from "axios";

export default {
  name: "Register",
  data: function(){
    return {
      username: "",
      email: "",
      password: "",
      message: ""
    }
  },
  methods: {
    register: function(username, email, password){
      axios.post(this.$baseURL + "/users/", {
        username: username,
        email: email,
        password: password
      }).then(response => {
        if (response.status === 200){
          this.message = "User successfully created, you can now login"
        }else{
          this.message = "An error occurred creating the user"
          console.error(response.data)
          console.error(response.statusText)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>